import { KeycloakAuthService } from 'src/app/services/keycloak-auth.service';

export const environment = {
    production: false,
    environment: 'REC',
    apiKey: 'AIzaSyCU-RFgD67SOM02pShLuyk1vXSpnmdcC20',
    dynatraceKey: '1623d230d17/bf53810kfz/ffcd6848403aa382',
    lienHermesMesComm: 'https://pp-interactive-tessi-veolia.tessitechno.fr/interactive/',
    lienCatalogueService: 'https://veglobal.service-now.com/water?id=sc_cat_item&sys_id=164a99951bab2c5c83faa868b04bcb83',
    lienCourrierEnMasse: 'https://staging-veolia.ezdoc.fr/bulk_mails',
    lienModelExtraction: 'https://docs.google.com/spreadsheets/d/1XSZutTzRj_wnyXOas8fmmg1Acyl1F6ACpvRZUVll1tw/edit#gid=0',
    lienGuideUtilisateur: 'https://docs.google.com/presentation/d/16lGK4h7D0AwE8mgneyElc70BXY03zsNB7iivWanDW3A/edit#slide=id.g1122694abe2_0_1',
    lienModeleCommunications: 'https://drive.google.com/drive/u/0/folders/1AwH8oDleXJjjvmTH0i-tUo8xAvz62OzU',
    publicChapterEmail: 'fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com',
    apiURL: "https://p3saek3gh9.execute-api.eu-west-1.amazonaws.com/rec",
    s3BucketFlux: 'veolia-osiris-echange-rec',
    awsRegion: "eu-west-1",
    googleClientId: "290638486407-sgr8jdjs5aaip3eqc2fc4q2pf80k7h8h.apps.googleusercontent.com",
    identityPoolId: "eu-west-1:6b7a6192-2f0c-4dc5-881a-e42f7461abcb",
    build: "143",
    commit: "c6b1b5df4f1004cc65e26262d953b1d6d2a68d07",

    auth: {
      config: {
        config: {
          url: 'https://keycloak-hom.dir.istefr.fr/',
          realm: 'vef',
          clientId: 'MERCURE',
        },
        initOptions: {
          onLoad: 'check-sso',
          flow: 'standard',
          'enable-cors': true,
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri:
            'https://rec-mercure.dir.istefr.fr/assets/silent-check-sso.html',
          'public-client': true,
        },
      },
      loginRedirectUri: 'https://rec-mercure.dir.istefr.fr/configuration',
      logoutRedirectUri: 'https://rec-mercure.dir.istefr.fr/home',
      provider: KeycloakAuthService,
      federatedSignInProvider: 'keycloak-hom.dir.istefr.fr/realms/vef',
    },
};
